
export default class FileLoader {

    constructor(urls = []) {
        this.urls = urls;
    }

    load() {
        let promises = [];

        const cacheBustString = '?v={cacheBustetring}'

        this.urls.forEach((file) => {
            let fileExt = file.split('.').pop();
            if (fileExt !== undefined) {
                var promise = new Promise(function(resolve, reject) {
                    switch (fileExt) {
                        case 'css':
                            let link = document.createElement('link');
                            link.setAttribute('href', file + cacheBustString);
                            link.setAttribute('rel', 'stylesheet');
                            link.addEventListener('load', function() {
                                resolve(link);
                            }, false);
                            link.addEventListener('error', function() {
                                reject(new Error("Failed to load " + file));
                            }, false);
                            document.head.appendChild(link);
                            break;
                        case 'js':
                            let script = document.createElement('script');
                            script.setAttribute('src', file + cacheBustString);
                            script.addEventListener('load', function() {
                                resolve(script);
                            }, false);
                            script.addEventListener('error', function() {
                                reject(new Error("Failed to load " + file));
                            }, false);
                            document.body.appendChild(script);
                            break;
                    }
                });
                promises.push(promise);
            }
        });

        if(promises.length === 0) {
            promises.push(new Promise(function(resolve, reject) { resolve(); }));
        }

        return Promise.all(promises);
    }
}