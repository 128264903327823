import preloader from './preloader/Preloader.js';
import FileLoader from './helpers/FileLoader';

let defaultConfig = {
    ref: 'next-venue-wizard-component'
};
let config = Object.assign({}, defaultConfig);
let loaded = false;

const configAsArrayKeys = ['subLocations'];

function loadWizard() {
    if(loaded) {
        return new Promise(function(resolve, reject) {
            resolve();
        });
    }

    let urls = [
        process.env.MIX_WIZARD_URL + '/wizard/js/app.js'
    ];

    let loader = new FileLoader(urls);
    preloader.open();

    return loader.load()
        .then(() => {
            loaded = true;
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            preloader.close();
        })
}

function openWizard(config) {
    let ref = config.ref;
    if(window.nextVenueTool.vm && window.nextVenueTool.vm.$refs.hasOwnProperty(ref)) {
        window.nextVenueTool.vm.$refs[ref].open(config);
    } else {
        console.log('[Next Venue Tool] Wizard component with ref ' + ref + ' not found. Unable to open tool!');
    }
}

function camelCase(input) {
    return input.toLowerCase().replace(/-(.)/g, function(match, group1) {
        return group1.toUpperCase();
    });
}

let open = function (localConfig) {
    loadWizard().then(() => {
        openWizard(Object.assign({}, config, localConfig));
    });
}

let close = function() {
    let ref = config.ref;
    if(window.nextVenueTool.vm && window.nextVenueTool.vm.$refs.hasOwnProperty(ref)) {
        window.nextVenueTool.vm.$refs[ref].close();
    } else {
        console.log('[Next Venue Tool] Wizard component with ref ' + ref + ' not found. Unable to close tool!');
    }
}

let isActive = function() {
    let ref = config.ref;
    if(window.nextVenueTool.vm && window.nextVenueTool.vm.$refs.hasOwnProperty(ref)) {
        return window.nextVenueTool.vm.$refs[ref].active;
    } else {
        return false;
    }
}

window.nextVenueTool = {
    open: open,
    close: close,
    isActive: isActive,
    setConfig: (options) => {
        config = Object.assign({}, defaultConfig, options);
    },
    vm: null
};

let activationPoints = document.querySelectorAll("[next-venue-open]");
activationPoints.forEach((element) => {
   element.addEventListener('click', (e) => {
       let currentUrl = window.location.href;
       let hashIndex = currentUrl.indexOf('#');

       if (hashIndex !== -1) {
           let newUrl = currentUrl.substring(0, hashIndex);
           history.replaceState(null, null, newUrl);
       }

       e.preventDefault();
       let localConfig = {};
       let configAttributes = element.getAttributeNames().filter((attribute) => { return attribute.startsWith("next") && attribute !== 'next-venue-open'});
       configAttributes.forEach((attribute) => {
           if(attribute.startsWith('next-')) {
               let attributeKey = camelCase(attribute.replace(/^(next-)/, ""));
               let value = element.getAttribute(attribute);

               if(value === 'true') {
                   value = true;
               }
               else if(value === 'false') {
                   value = false;
               }

               localConfig[attributeKey] = configAsArrayKeys.includes(attributeKey) ? value.split(',') : value;
           }
       });

       loadWizard().then(() => {
           openWizard(Object.assign({}, config, localConfig));
       });
   });
});

window.addEventListener('load', function (event) {
    if(window.location.hash && window.location.hash.startsWith("#/next-venue")) {
        loadWizard().then(() => {
            openWizard(Object.assign({}, config));
        });
    }
});