
const preloaderId = 'next-venue-preloader';

export default class Preloader {
    static open() {
        require('./Preloader.css');
        let preloader = document.createElement('div');
        preloader.setAttribute('id', preloaderId);
        preloader.innerHTML = '<div class="next-venue-container"><div class="next-venue-lds-ring"><div></div><div></div><div></div><div></div></div></div>';
        document.body.appendChild(preloader);
        setTimeout(() => {
            preloader.classList.add('show');
        }, 50);
    }

    static close() {
        let preloader = document.getElementById(preloaderId);
        if(preloader) {
            preloader.classList.remove('show');
            setTimeout(() => {
                preloader.remove();
            }, 300);
        }
    }
};